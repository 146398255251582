import { SelectOption, SelectOptionGroup } from '@mmx/shared'

import { Workflow } from '../models'

export const WorkflowEventTypes: SelectOptionGroup<Workflow.TYPE>[] = [
  {
    label: 'Appointments',
    options: [
      {
        value: Workflow.TYPE.APPOINTMENT_NEW,
        viewValue: 'Appointment scheduled',
        hint: 'Run when an appointment is scheduled',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_RESCHEDULED,
        viewValue: 'Appointment rescheduled',
        hint: `Run when an appointment's start time is changed`,
      },
      {
        value: Workflow.TYPE.APPOINTMENT_CANCELLED,
        viewValue: 'Appointment cancelled',
        hint: 'Run when an appointment is cancelled',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_REMINDER,
        viewValue: 'Appointment reminder',
        hint: 'Run X days before an appointment',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_CONFIRMATION,
        viewValue: 'Appointment confirmation required',
        hint: 'Run X days before an appointment asking the patient to reply',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_CONFIRMATION_RECEIVED,
        viewValue: 'Appointment confirmation received',
        hint: 'Run when the patient responds to a confirmation (affirmatively or negatively)',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_STARTED,
        viewValue: 'Appointment service started',
        hint: 'Run when an appointment begins',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_COMPLETED,
        viewValue: 'Appointment service completed',
        hint: 'Run when an appointment is over',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_PAST,
        viewValue: 'Appointment past',
        hint: 'Run X days after an appointment',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_NO_SHOW,
        viewValue: 'Appointment no show',
        hint: 'Run when an appointment is marked as "no show"',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_NEEDS_RESCHEDULE,
        viewValue: 'Appointment needs reschedule',
        hint: 'Run when an appointment is marked as "needs reschedule"',
      },
    ],
  },
  {
    label: 'Orders',
    options: [
      {
        value: Workflow.TYPE.ORDER_CREATED,
        viewValue: 'Order created',
        hint: 'Run when an order is created',
      },
    ],
  },
  {
    label: 'Appointments Recommendations',
    options: [
      {
        value: Workflow.TYPE.APPOINTMENT_RECOMMENDATION_CREATED,
        viewValue: 'Appointment recommendation created',
        hint: 'Run when an appointment recommendation needs to be scheduled',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_RECOMMENDATION_REMINDER,
        viewValue: 'Appointment recommendation reminder',
        hint: `Run X days before get close to the targeted appointment date`,
      },
    ],
  },
  {
    label: 'Appointments Requests',
    options: [
      {
        value: Workflow.TYPE.APPOINTMENT_REQUEST_CREATED,
        viewValue: 'Appointment request created',
        hint: 'Run when an appointment request is created',
      },
      {
        value: Workflow.TYPE.APPOINTMENT_REQUEST_REMINDER,
        viewValue: 'Appointment request reminder',
        hint: `Run X days of inactivity`,
      },
      {
        value: Workflow.TYPE.APPOINTMENT_REQUEST_SUBMITTED,
        viewValue: 'Appointment request submitted',
        hint: 'Run when an appointment request is submitted by a patient',
      },
    ],
  },
  {
    label: 'Intakes',
    options: [
      {
        value: Workflow.TYPE.INTAKE_REMINDER,
        viewValue: 'Intake reminder',
        hint: 'Run X days before an appointment when an intake is required',
      },
      {
        value: Workflow.TYPE.INTAKE_CONFIRMED,
        viewValue: 'Intake confirmed',
        hint: 'Run when an intake is submitted a patient',
      },
    ],
  },
  {
    label: 'Invoices',
    options: [
      {
        value: Workflow.TYPE.INVOICE_CONFIRMED,
        viewValue: 'Invoice confirmed (bill created)',
        hint: 'Run when an invoice is confirmed',
      },
      {
        value: Workflow.TYPE.INVOICE_REMINDER,
        viewValue: 'Invoice reminder',
        hint: 'Run X days before the due date',
      },
      {
        value: Workflow.TYPE.INVOICE_PAST_DUE,
        viewValue: 'Invoice past due',
        hint: 'Run X days after the due date',
      },
      {
        value: Workflow.TYPE.INVOICE_PAYMENT,
        viewValue: 'Invoice payment',
        hint: 'Run when a payment is made towards an invoice',
      },
      {
        value: Workflow.TYPE.INVOICE_PAID,
        viewValue: 'Invoice paid',
        hint: 'Run when an invoice is marked fully paid',
      },
      {
        value: Workflow.TYPE.INVOICE_PAYMENT_PLAN_CANCELED,
        viewValue: 'Invoice payment plan canceled',
        hint: 'Run when a payment plan is canceled',
      },
    ],
  },
  {
    label: 'Transactions',
    options: [
      {
        value: Workflow.TYPE.TRANSACTION_CREATED,
        viewValue: 'Transaction created',
        hint: 'Run when a transaction is created',
      },
    ],
  },
  {
    label: 'Results',
    options: [
      {
        value: Workflow.TYPE.RESULTS_AVAILABLE,
        viewValue: 'Results available',
        hint: 'Run when the results from a study become available',
      },
    ],
  },
  {
    label: 'Surveys',
    options: [
      {
        value: Workflow.TYPE.POST_APPOINTMENT_SURVEY,
        viewValue: 'Appointment survey',
        hint: 'Runs after an appointment asking the patient to complete a survey',
      },
    ],
  },
  {
    label: 'Wait List',
    options: [
      {
        value: Workflow.TYPE.WAIT_LIST_REACH_OUT,
        viewValue: 'Reach out to patients',
        hint: 'Run to reach out to patients on the wait list',
      },
    ],
  },
  {
    label: 'Work List',
    options: [
      {
        value: Workflow.TYPE.WORK_LIST_AUTOMATION,
        viewValue: 'Work List Automation',
        hint: 'Run when an item is added to a work list',
      },
    ],
  },
  {
    label: 'Eligibilities',
    options: [
      {
        value: Workflow.TYPE.ELIGIBILITY_APPOINTMENT_PRE,
        viewValue: 'Pull eligibilities prior to the appointment',
        hint: 'Run to update eligibilities X days before an appointment',
      },
    ],
  },
]

export const WorkflowActionTypes: SelectOption<Workflow.ACTION>[] = [
  {
    value: Workflow.ACTION.MESSAGE_PATIENT,
    viewValue: 'Send message to patient',
  },
  {
    value: Workflow.ACTION.INSURANCE_VERIFY,
    viewValue: 'Run insurance verification',
  },
  {
    value: Workflow.ACTION.INSURANCE_DISCOVER,
    viewValue: 'Run insurance discovery',
  },
  {
    value: Workflow.ACTION.NOTIFY_CLINICIANS,
    viewValue: 'Send notification to employees',
  },
  {
    value: Workflow.ACTION.PUSH_WEBHOOK,
    viewValue: 'Push notification to webhook',
  },
  { value: Workflow.ACTION.EMAIL, viewValue: 'Send document via email' },
  { value: Workflow.ACTION.FAX, viewValue: 'Send document via fax' },
]
